import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

export default function Bill_print() {

  const { isAuthenticated, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate("/login");
    }

    const getUser = async () => {
      try {
        const response = await fetch(
          "https://nansijewellers.com/php/get_user.php",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (isAuthenticated) {
      getUser();
    }
  }, [isAuthenticated, loading, navigate]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state while checking authentication
  }

  return (
    <div>
      <div className="container">
        <div className="row justify-content-center py-4">
          <div className="col-md-8">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td colSpan="3">
                    Bindal Jewellers
                  </td>
                  <td colSpan="2">
                    Date 03-07-24
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Item</th>
                  <th>Net Wt.</th>
                  <th>Tnch+lbr</th>
                  <th>Fine Gold</th>
                  <th>Amount</th>
                </tr>
                <tr>
                  <td>Dane</td>
                  <td>5 gr</td>
                  <td>80</td>
                  <td>4 gr</td>
                  <td></td>
                </tr>
                {/* <tr>
                  <td>Haye</td>
                  <td>2 gr</td>
                  <td>80</td>
                  <td>0 gr</td>
                  <td></td>
                </tr> */}
                <tr>
                  <td colSpan="3">Total</td>
                  <td>4 gr</td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan="3">Balance [01-07-24] </td>
                  <td>5 gr</td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan="3">Closing Balance</td>
                  <td>9 gr</td>
                  <td> 00 -/</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
