import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import "./css/bill.css";

export default function Bill() {
  const { isAuthenticated, loading } = useAuth();
  const navigate = useNavigate();

  // Function to get the current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    shop_name: "",
    shop_owner_name: "",
    deal_type: "",
    date: getCurrentDate(), // Set initial date value
    item_name: "",
    net_wt: "",
    touch_lbr: "",
    fine_gold: "",
    gold_rate: "",
    amount: "",
  });

  const [shopNames, setShopNames] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, loading, navigate]);

  useEffect(() => {
    const fetchShopNames = async () => {
      try {
        const response = await fetch("https://nansijewellers.com/php/get_shop_name.php");
        const data = await response.json();
        if (data.success) {
          setShopNames(data.shops);
        } else {
          setMessage(data.message);
        }
      } catch (error) {
        console.error("Error fetching shop names:", error);
        setMessage("Error fetching shop names.");
      }
    };

    fetchShopNames();
  }, []);

  const fetchShopOwnerName = async (shopName) => {
    try {
      const response = await fetch(`https://nansijewellers.com/php/get_shop_owner.php?shop_name=${shopName}`);
      const data = await response.json();
      if (data.success) {
        setFormData((prevData) => ({
          ...prevData,
          shop_owner_name: data.shop_owner_name,
        }));
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      console.error("Error fetching shop owner name:", error);
      setMessage("Error fetching shop owner name.");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      // Calculate fine_gold if net_wt or touch_lbr is updated
      if (name === "net_wt" || name === "touch_lbr") {
        updatedData.fine_gold = calculateFineGold(updatedData.net_wt, updatedData.touch_lbr);
      }

      return updatedData;
    });

    if (name === "shop_name") {
      fetchShopOwnerName(value);
    }
  };

  const calculateFineGold = (netWt, touchLbr) => {
    const netWeight = parseFloat(netWt) || 0;
    const touchLabour = parseFloat(touchLbr) || 0;
    return (netWeight * touchLabour).toFixed(2); // Returns the calculated fine gold as a string with 2 decimal places
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://nansijewellers.com/php/submit_bill.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      setMessage(data.message);

      if (data.success) {
        setFormData({
          shop_name: "",
          shop_owner_name: "",
          deal_type: "",
          date: getCurrentDate(), // Reset date value to current date
          item_name: "",
          net_wt: "",
          touch_lbr: "",
          fine_gold: "",
          gold_rate: "",
          amount: "",
        });
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("Error submitting bill.");
    }
  };

  return (
    <div>
      <div className="container-fluid" id="content1">
        <div className="row justify-content-center py-4">
          <div className="col-md-6">
            <section className="container bill-section">
              <header>Bill Form</header>

              <form method="post" className="form" onSubmit={handleSubmit}>
                <div className="column">
                  <div className="input-box">
                    <label>Shop Name :</label>
                    <select
                      name="shop_name"
                      className="form-select mt-2"
                      value={formData.shop_name}
                      onChange={handleChange}
                    >
                      <option value hidden></option>
                      {shopNames.map((shop, index) => (
                        <option key={index} value={shop}>
                          {shop}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="input-box">
                    <label>Shop Owner Name :</label>
                    <input
                      className="mt-0"
                      type="text"
                      name="shop_owner_name"
                      placeholder="Name of shop owner"
                      value={formData.shop_owner_name}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>

                <div className="column">
                  <div className="input-box">
                    <label>Deal Type :</label>
                    <select
                      name="deal_type"
                      className="form-select mt-2"
                      value={formData.deal_type}
                      onChange={handleChange}
                    >
                      <option value hidden></option>
                      <option>Sale</option>
                      <option>Deposit</option>
                    </select>
                  </div>
                  <div className="input-box">
                    <label>Date :</label>
                    <input
                      className="mt-0"
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="column">
                  <div className="input-box">
                    <label>Item Name :</label>
                    <input
                      className="mt-0"
                      type="text"
                      name="item_name"
                      placeholder="Enter Item Name"
                      value={formData.item_name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-box">
                    <label>Net wt. :</label>
                    <input
                      className="mt-0"
                      type="text"
                      name="net_wt"
                      placeholder="Net Weight of Item"
                      value={formData.net_wt}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="column">
                  <div className="input-box">
                    <label>Tounch+lbr :</label>
                    <input
                      className="mt-0"
                      type="text"
                      name="touch_lbr"
                      placeholder="Enter Tounch + Labour"
                      value={formData.touch_lbr}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-box">
                    <label>Fine Gold :</label>
                    <input
                      className="mt-0"
                      type="text"
                      name="fine_gold"
                      placeholder="Enter Fine Gold"
                      value={formData.fine_gold}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>
                </div>

                <div className="column">
                  <div className="input-box">
                    <label>Gold Rate :</label>
                    <input
                      className="mt-0"
                      type="text"
                      name="gold_rate"
                      placeholder="Current Gold Rate"
                      value={formData.gold_rate}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-box">
                    <label>Amount :</label>
                    <input
                      className="mt-0"
                      type="text"
                      name="amount"
                      placeholder="Total Amount"
                      value={formData.amount}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <input
                  type="submit"
                  value="Submit"
                  id="submit_btn"
                  name="Submit"
                />
              </form>
              {message && <p>{message}</p>}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
